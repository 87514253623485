import React, { Component } from "react";

import { Link } from "react-router-dom";
// import assets
import defaultBcg from "../assets/img/defaul.jpg";

// import components
import Banner from "../Components/Banner/Banner";
import { HotelContext } from "../Context/HotelContext";
import StyledHero from "../Components/StyledHero/StyledHero";
import RoomsContainer from "../Components/RoomsContainer/RoomsContainer";
import SingleHotel from "./SingleHotel";
import Title from "../Components/Title/Title";

export default class Hotel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: this.props.match.params.slug,
      defaultBcg,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  static contextType = HotelContext;
  // componentDidMount() {}

  render() {
    const { getHotel } = this.context;
    const hotel = getHotel(this.state.slug);

    if (!hotel) {
      return (
        <div className="error">
          <h3>no such Hotel could be found!</h3>
          <Link to="/" className="btn-primary">
            back to Hotels
          </Link>
        </div>
      );
    }

    const {
      name,
      address,
      phone,
      images,
      logo,
      mainImg,
      facebook
    } = hotel;

    const [...defaultImg] = images;
    return (
      <>
        <StyledHero img={mainImg}>
        </StyledHero>
        <div class="container text-center">
        <Title title={name} />
          <div class="hotel-styles">
            <div class="row">
              <div class="col-md">
                <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="true">
                  <div class="carousel-indicators">
                    {images.map((item, index) => {
                      if (item == images[0]) {
                        return <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={index} class="active" aria-current="true" aria-label="Slide 1"></button>
                      }
                      else {
                        return <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={index} ></button>
                      }
                    })}

                  </div>
                  <div class="carousel-inner">
                    {images.map((item, index) => {
                      if (item == images[0]) {
                        return <div class="carousel-item active">
                          <img src={item} style={{ position: "relative", objectFit: "cover" }} width="100%" height="500" alt="..." />
                        </div>
                      }
                      else {
                        return <div class="carousel-item">
                          <img src={item} style={{ position: "relative", objectFit: "cover" }} width="100%" height="500" alt={index} />
                        </div>;
                      }
                    })}

                  </div>
                  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
              <div class="col-md" id="hotel-info">
                <img src={logo} style={{objectFit: "cover"}} height="300px" width="50%" alt="" />
                  <div className="single-Hotel-info">
                    <article className="desc">
                      <h2>Address:</h2>
                      <h4>{address}</h4>
                    </article>
                    <article className="desc">
                      <h2>Phone:</h2>
                      <h4>{phone}</h4>
                    </article>
                    <article className="desc">
                      <h2>Facebook:</h2>
                      <a className="facebook" href={facebook}>{facebook}</a>
                    </article>
                  </div>
              </div>
            </div>
          </div>
          <SingleHotel slug={this.state.slug} />
        </div>
      </>
    );
  }
}
