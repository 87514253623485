import dcb_deluxe_double_img1 from "../assets/img/hotels/DCboutique/DeluxeDouble/1.jpg";
import dcb_deluxe_double_img2 from "../assets/img/hotels/DCboutique/DeluxeDouble/2.jpg";
import dcb_deluxe_double_img3 from "../assets/img/hotels/DCboutique/DeluxeDouble/3.jpg";
import dcb_deluxe_double_img4 from "../assets/img/hotels/DCboutique/DeluxeDouble/4.jpg";
import dcb_deluxe_double_img5 from "../assets/img/hotels/DCboutique/DeluxeDouble/5.jpg";
import dcb_deluxe_double_img6 from "../assets/img/hotels/DCboutique/DeluxeDouble/6.jpg";
import dcb_deluxe_double_img7 from "../assets/img/hotels/DCboutique/DeluxeDouble/7.jpg";

import dcb_deluxe_triple_img1 from "../assets/img/hotels/DCboutique/DeluxeTriple/1.jpg";
import dcb_deluxe_triple_img2 from "../assets/img/hotels/DCboutique/DeluxeTriple/2.jpg";
import dcb_deluxe_triple_img3 from "../assets/img/hotels/DCboutique/DeluxeTriple/3.jpg";
import dcb_deluxe_triple_img4 from "../assets/img/hotels/DCboutique/DeluxeTriple/4.jpg";
import dcb_deluxe_triple_img5 from "../assets/img/hotels/DCboutique/DeluxeTriple/5.jpg";

import dcb_superior_double_img1 from "../assets/img/hotels/DCboutique/SuperiorDouble/1.jpg";
import dcb_superior_double_img2 from "../assets/img/hotels/DCboutique/SuperiorDouble/2.jpg";
import dcb_superior_double_img3 from "../assets/img/hotels/DCboutique/SuperiorDouble/3.jpg";
import dcb_superior_double_img4 from "../assets/img/hotels/DCboutique/SuperiorDouble/4.jpg";
import dcb_superior_double_img5 from "../assets/img/hotels/DCboutique/SuperiorDouble/5.jpg";
import dcb_superior_double_img6 from "../assets/img/hotels/DCboutique/SuperiorDouble/6.jpg";

import dcb_superior_triple_img1 from "../assets/img/hotels/DCboutique/SuperiorTriple/1.jpg"
import dcb_superior_triple_img2 from "../assets/img/hotels/DCboutique/SuperiorTriple/2.jpg"
import dcb_superior_triple_img3 from "../assets/img/hotels/DCboutique/SuperiorTriple/3.jpg"

import dc_deluxe_double_img1 from "../assets/img/hotels/DuongChau/DeluxeDouble/1.jpg"
import dc_deluxe_double_img2 from "../assets/img/hotels/DuongChau/DeluxeDouble/2.jpg"
import dc_deluxe_double_img3 from "../assets/img/hotels/DuongChau/DeluxeDouble/3.jpg"
import dc_deluxe_double_img4 from "../assets/img/hotels/DuongChau/DeluxeDouble/4.jpg"
import dc_deluxe_double_img5 from "../assets/img/hotels/DuongChau/DeluxeDouble/5.jpg"
import dc_deluxe_double_img6 from "../assets/img/hotels/DuongChau/DeluxeDouble/6.jpg"

import dc_superior_double_img1 from "../assets/img/hotels/DuongChau/SuperiorDouble/1.jpg"
import dc_superior_double_img2 from "../assets/img/hotels/DuongChau/SuperiorDouble/2.jpg"
import dc_superior_double_img3 from "../assets/img/hotels/DuongChau/SuperiorDouble/3.jpg"

import dc_superior_quad_img1 from "../assets/img/hotels/DuongChau/SuperiorQuad/1.jpg"
import dc_superior_quad_img2 from "../assets/img/hotels/DuongChau/SuperiorQuad/2.jpg"
import dc_superior_quad_img3 from "../assets/img/hotels/DuongChau/SuperiorQuad/3.jpg"
import dc_superior_quad_img4 from "../assets/img/hotels/DuongChau/SuperiorQuad/4.jpg"
import dc_superior_quad_img5 from "../assets/img/hotels/DuongChau/SuperiorQuad/5.jpg"
import dc_superior_quad_img6 from "../assets/img/hotels/DuongChau/SuperiorQuad/6.jpg"

import dc_superior_triple_img1 from "../assets/img/hotels/DuongChau/SuperiorTriple/1.jpg"
import dc_superior_triple_img2 from "../assets/img/hotels/DuongChau/SuperiorTriple/2.jpg"
import dc_superior_triple_img3 from "../assets/img/hotels/DuongChau/SuperiorTriple/3.jpg"
import dc_superior_triple_img4 from "../assets/img/hotels/DuongChau/SuperiorTriple/4.jpg"
import dc_superior_triple_img5 from "../assets/img/hotels/DuongChau/SuperiorTriple/5.jpg"

import nh_deluxe_double_img5 from "../assets/img/hotels/NgocHa/DeluxeDouble/5.jpg"
import nh_deluxe_double_img6 from "../assets/img/hotels/NgocHa/DeluxeDouble/6.jpg"

import ng_superior_twin_img1 from"../assets/img/hotels/NgocHa/SuperiorTwin/1.jpg"
import ng_superior_twin_img2 from"../assets/img/hotels/NgocHa/SuperiorTwin/2.jpg"

const rooms = [
  {
    sys: {
      id: "1",
      hotel: "dc-boutique"
    },
    fields: [
      {
        name: "Deluxe Double",
        slug: "dc-boutique-deluxe-double",
        price: "600.000",
        capacity: 2,
        image: dcb_deluxe_double_img1,
        images: [
          {
            fields: {
              file: {
                url: dcb_deluxe_double_img1,
              },
            },
          },
          {
            fields: {
              file: {
                url: dcb_deluxe_double_img2,
              },
            },
          },
          {
            fields: {
              file: {
                url: dcb_deluxe_double_img3,
              },
            },
          },
          {
            fields: {
              file: {
                url: dcb_deluxe_double_img4,
              },
            },
          },
          {
            fields: {
              file: {
                url: dcb_deluxe_double_img5,
              },
            },
          },
          {
            fields: {
              file: {
                url: dcb_deluxe_double_img6,
              },
            },
          },
          {
            fields: {
              file: {
                url: dcb_deluxe_double_img7,
              },
            },
          },
        ],
      },
      {
        name: "Deluxe Triple",
        slug: "dc-boutique-deluxe-triple",
        price: "650.000",
        capacity: 3,
        image: dcb_deluxe_triple_img1,
        images: [
          {
            fields: {
              file: {
                url: dcb_deluxe_triple_img1,
              },
            },
          },
          {
            fields: {
              file: {
                url: dcb_deluxe_triple_img2,
              },
            },
          },
          {
            fields: {
              file: {
                url: dcb_deluxe_triple_img3,
              },
            },
          },
          {
            fields: {
              file: {
                url: dcb_deluxe_triple_img4,
              },
            },
          },
          {
            fields: {
              file: {
                url: dcb_deluxe_triple_img5,
              },
            },
          },
        ],
      },
      {
        name: "Superior Double",
        slug: "dc-boutique-superior-double",
        price: "500.000",
        capacity: 2,
        image: dcb_superior_double_img1,
        images: [
          {
            fields: {
              file: {
                url: dcb_superior_double_img1,
              },
            },
          },
          {
            fields: {
              file: {
                url: dcb_superior_double_img2,
              },
            },
          },
          {
            fields: {
              file: {
                url: dcb_superior_double_img3,
              },
            },
          },
          {
            fields: {
              file: {
                url: dcb_superior_double_img4,
              },
            },
          },
          {
            fields: {
              file: {
                url: dcb_superior_double_img5,
              },
            },
          },
          {
            fields: {
              file: {
                url: dcb_superior_double_img6,
              },
            },
          },
        ],
      },
      {
        name: "Superior Triple",
        slug: "dc-boutique-superior-triple",
        type: "single",
        price: "500.000",
        capacity: 3,
        image: dcb_superior_triple_img1,
        images: [
          {
            fields: {
              file: {
                url: dcb_superior_triple_img1,
              },
            },
          },
          {
            fields: {
              file: {
                url: dcb_superior_triple_img2,
              },
            },
          },
          {
            fields: {
              file: {
                url: dcb_superior_triple_img3,
              },
            },
          },
        ],
      },
    ],
  },
  {
    sys: {
      id: "2",
      hotel: "duongchau"
    },
    fields: [
      {
        name: "Deluxe Double",
        slug: "dc-deluxe-double",
        capacity: 2,
        price: "500.000",
        image: dc_deluxe_double_img1,
        images: [
          {
            fields: {
              file: {
                url: dc_deluxe_double_img1,
              },
            },
          },
          {
            fields: {
              file: {
                url: dc_deluxe_double_img2,
              },
            },
          },
          {
            fields: {
              file: {
                url: dc_deluxe_double_img3,
              },
            },
          },
          {
            fields: {
              file: {
                url: dc_deluxe_double_img4,
              },
            },
          },
          {
            fields: {
              file: {
                url: dc_deluxe_double_img5,
              },
            }
          },

          {
            fields: {
              file: {
                url: dc_deluxe_double_img6,
              },
            },
          },
        ],
      },
      {
        name: "Superior Double",
        slug: "dc-superior-double",
        type: "single",
        capacity: 2,
        price: "450.000",
        image: dc_superior_double_img1,
        images: [
          {
            fields: {
              file: {
                url: dc_superior_double_img1,
              },
            },
          },
          {
            fields: {
              file: {
                url: dc_superior_double_img2,
              },
            },
          },
          {
            fields: {
              file: {
                url: dc_superior_double_img3,
              },
            },
          },
        ],
      },
      {
        name: "Superior Quad",
        slug: "dc-superior-quad",
        type: "single",
        capacity: 4,
        price: "500.000",
        image: dc_superior_quad_img1,
        images: [
          {
            fields: {
              file: {
                url: dc_superior_quad_img1,
              },
            },
          },
          {
            fields: {
              file: {
                url: dc_superior_quad_img2,
              },
            },
          },
          {
            fields: {
              file: {
                url: dc_superior_quad_img3,
              },
            },
          },
          {
            fields: {
              file: {
                url: dc_superior_quad_img4,
              },
            },
          },
          {
            fields: {
              file: {
                url: dc_superior_quad_img5,
              },
            },
          },
          {
            fields: {
              file: {
                url: dc_superior_quad_img6,
              },
            },
          },
        ],
      },
      {
        name: "Superior Triple",
        slug: "dc-superior-triple",
        type: "single",
        capacity: 1,
        image: dc_superior_triple_img1,
        images: [
          {
            fields: {
              file: {
                url: dc_superior_triple_img1,
              },
            },
          },
          {
            fields: {
              file: {
                url: dc_superior_triple_img2,
              },
            },
          },
          {
            fields: {
              file: {
                url: dc_superior_triple_img3,
              },
            },
          },
          {
            fields: {
              file: {
                url: dc_superior_triple_img4,
              },
            },
          },
          {
            fields: {
              file: {
                url: dc_superior_triple_img5,
              },
            },
          },
        ],
      },
    ],
  },
  {
    sys: {
      id: "3",
      hotel: "ngoc-ha"
    },
    fields: [
      {
        name: "Deluxe Double",
        slug: "nh-deluxe-double",
        capacity: 2,
        price: "500.000",
        image: nh_deluxe_double_img5,
        images: [
          {
            fields: {
              file: {
                url: nh_deluxe_double_img5,
              },
            },
          },
          {
            fields: {
              file: {
                url: nh_deluxe_double_img6,
              },
            },
          },
        ],
      },
      {
        name: "Superior Double",
        slug: "nh-superior-double",
        type: "single",
        capacity: 2,
        price: "450.000",
        image: nh_deluxe_double_img6,
        images: [
          {
            fields: {
              file: {
                url: nh_deluxe_double_img6,
              },
            },
          },
          {
            fields: {
              file: {
                url: nh_deluxe_double_img5,
              },
            },
          },
        ],
      },
      {
        name: "Superior Twin",
        slug: "nh-superior-twin",
        type: "single",
        capacity: 4,
        price: "500.000",
        image: ng_superior_twin_img1,
        images: [
          {
            fields: {
              file: {
                url: ng_superior_twin_img1,
              },
            },
          },
          {
            fields: {
              file: {
                url: ng_superior_twin_img2,
              },
            },
          },
        ],
      },
    ]
  }
]

export default rooms;
