import React, { Component } from "react";

// import data
import hotelsData from "../Data/hotels";

// react context-api
const HotelContext = React.createContext();

class HotelProvider extends Component {
  state = {
    hotels: [],
    sortedhotels: [],
    featuredhotels: [],
    loading: true,
    type: "all",
    capacity: 1,
    price: 0,
    minPrice: 0,
    maxPrice: 0,
    minSize: 0,
    maxSize: 0,
    breakfast: false,
    pets: false,
  };

  // getDate
  componentDidMount() {
    // this.getData
    let hotels = this.formatDate(hotelsData);
    let featuredhotels = hotels.filter((Hotel) => Hotel.featured === true);

    let maxPrice = Math.max(...hotels.map((hotelsData) => hotelsData.price));
    let maxSize = Math.max(...hotels.map((hotelsData) => hotelsData.size));

    this.setState({
      hotels,
      featuredhotels,
      sortedhotels: hotels,
      loading: false,
      price: maxPrice,
      maxPrice,
      maxSize,
    });
  }

  formatDate(hotelsDatas) {
    let temphotelsDatas = hotelsDatas.map((hotelsData) => {
      let id = hotelsData.sys.id;
      let images = hotelsData.fields.images.map((image) => image.fields.file.url);
      let mainImg = hotelsData.fields.mainImg;
      let Hotel = { ...hotelsData.fields, images, id, mainImg };
      return Hotel;
    });
    return temphotelsDatas;
  }

  getHotel = (slug) => {
    let temphotels = [...this.state.hotels];
    const Hotel = temphotels.find((Hotel) => Hotel.slug === slug);
    return Hotel;
  };
  getHotelName = () =>{
    let temphotels = [...this.state.hotels];
    const HotelName = temphotels.map((Hotel) => Hotel.name);
    return HotelName;
  }
  getHotelSlug = () =>{
    let temphotels = [...this.state.hotels];
    const HotelSlug = temphotels.map((Hotel) => Hotel.slug);
    return HotelSlug;
  }
  handleChange = (event) => {
    /* const type = event.target.type;
    const name = event.target.name;
    const value = event.target.value; */
    /* console.log(
      `this is type: ${type}, this is name: ${name}, this is value: ${value}`
    ); */

    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    console.log(target, value, name);

    this.setState(
      {
        [name]: value,
      },
      this.filterhotels
    );
  };

  filterhotels = () => {
    let {
      hotels,
      type,
      capacity,
      price,
      minSize,
      maxSize,
      breakfast,
      pets,
    } = this.state;

    // all the Hotel
    let temphotels = [...hotels];

    // transform value
    capacity = parseInt(capacity);
    price = parseInt(price);

    // filter by type
    if (type !== "all") {
      temphotels = temphotels.filter((Hotel) => Hotel.type === type);
    }

    // filter by capacity
    if (capacity !== 1) {
      temphotels = temphotels.filter((Hotel) => Hotel.capacity >= capacity);
    }

    // filter by price
    temphotels = temphotels.filter((Hotel) => Hotel.price <= price);

    // filter bt size
    temphotels = temphotels.filter(
      (Hotel) => Hotel.size >= minSize && Hotel.size <= maxSize
    );

    // filter by breakfast
    if (breakfast) {
      temphotels = temphotels.filter((Hotel) => Hotel.breakfast === true);
    }

    // filter by pets
    if (pets) {
      temphotels = temphotels.filter((Hotel) => Hotel.pets === true);
    }

    // change state
    this.setState({
      sortedhotels: temphotels,
    });
  };

  render() {
    return (
      <HotelContext.Provider
        value={{
          ...this.state,
          getHotel: this.getHotel,
          handleChange: this.handleChange,
          getHotelName: this.getHotelName,
          getHotelSlug: this.getHotelSlug,
        }}
      >
        {this.props.children}
      </HotelContext.Provider>
    );
  }
}

const HotelConsumer = HotelContext.Consumer;

export function withHotelConsumer(Component) {
  return function ConsumerWrapper(props) {
    return (
      <HotelConsumer>
        {(value) => <Component {...props} context={value} />}
      </HotelConsumer>
    );
  };
}

export { HotelProvider, HotelConsumer, HotelContext };
