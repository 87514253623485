import React from "react";

// import react-router-dom
import { Link } from "react-router-dom";

// import assets
import defaultImg from "../../assets/img/defaul.jpg";

// import prop-types
import PropTypes from "prop-types";

export default function HotelDetail({ hotel }) {
  const { name, slug, images, price, mainImg } = hotel;
  return (
    <article className="room">
      <div className="img-container">
        <img src={mainImg || defaultImg} style={{position:"relative", objectFit: "cover", height:"35vh"}} alt="single room" />
        <Link to={`/hotels/${slug}`} className="btn-primary room-link">
          {name}
        </Link>
      </div>
      <p className="room-info">{name}</p>
    </article>
  );
}

HotelDetail.prototype = {
  hotel: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
};
