import React from "react";

export default function Footer() {
  return (
    <footer className="footer">
      <h2>Duong Chau | Hotel Room Book</h2>
      {/* <p>
        &copy; <span>{year}</span> BeachResort.com - All Rights Copyright
        Reserved To{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com/SamiurRahmanMukul"
        >
          Samiur Rahman Mukul
        </a>
      </p> */}
    </footer>
  );
}
