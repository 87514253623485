import "./App.css";

// import react-router-dom
import { Route, Switch } from "react-router-dom";

// imports pages
import Home from "../Pages/Home";
// import Room from "../Pages/Room";
import SingleHotel from "../Pages/SingleHotel";
import SingleRoom from "../Pages/SingleRoom";
import Error from "../Pages/Error";

// import components
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import Hotel from "../Pages/Hotel";

function App() {
  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        {/* <Route exact path="/rooms/" component={Room} /> */}
        <Route exact path="/rooms/:slug" component={SingleHotel} />
        <Route exact path="/hotels/:slug" component={Hotel} />
        <Route exact path="/hotels/:slug/:roomslug" component={SingleRoom} />
        <Route component={Error} />
      </Switch>
      <Footer />
    </>
  );
}

export default App;
