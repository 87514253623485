import React, { Component } from "react";

// import react-router-dom
import { Link } from "react-router-dom";
import { HotelContext } from "../../Context/HotelContext";
// import assets
import Logo from "../../assets/img/svg/logo.svg";
import { FaAlignRight } from "react-icons/fa";
class Submenu extends React.Component {
  static contextType = HotelContext;
  render() {
    let { getHotelName, getHotelSlug } = this.context;
    console.log("getHotelName", getHotelName())
    const name = getHotelName()
    const slug = getHotelSlug()
    console.log("slugggg:", slug)
    return (
      <div class="dropdown-content">
        <a href={`/hotels/${slug[0]}`}>{name[0]}</a>
        <a href={`/hotels/${slug[1]}`}>{name[1]}</a>
        <a href={`/hotels/${slug[2]}`}>{name[2]}</a>
      </div>
    )
  }
}

export default class Navbar extends Component {
  state = {
    isOpen: false,
  };
  handleToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  render() {
    return (
      <nav className="navbar">
        <div className="nav-center">
          <div className="nav-header">
            <a href="/">
              <h1 style={{ color: "black", textDecoration: "none" }}>Trang chủ</h1>
            </a>
            <button
              type="button"
              className="nav-btn"
              onClick={this.handleToggle}
            >
            </button>
          </div>
          <div className="phoneHeader">
               <div class="dropdown">
               Chọn khách sạn
              <Submenu />
            </div>
          </div>
          <div className="headerOptions">
            <div
              className={this.state.isOpen ? "nav-links show-nav" : "nav-links"}
            >
             
            </div>
            <div class="dropdown">
            Chọn khách sạn
              <Submenu />
            </div>
          </div>
          
        </div>
      </nav>
    );
  }
}
