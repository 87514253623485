import React, { Component } from "react";

import { Link } from "react-router-dom";

// import assets
import defaultBcg from "../assets/img/defaul.jpg";
import Banner from "../Components/Banner/Banner";
// import components
import { RoomContext } from "../Context/Context";
import Title from "../Components/Title/Title";
import StyledHero from "../Components/StyledHero/StyledHero";

export default class SingleHotel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomslug: this.props.match.params.roomslug,
      slug: this.props.match.params.slug,
      defaultBcg,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  static contextType = RoomContext;
  // componentDidMount() {}

  render() {
    const { getRoom } = this.context;
    const room = getRoom(this.state.slug, this.state.roomslug);
    if (!room) {
      return (
        <div className="error">
          <h3>no such room could be found!</h3>
          <Link to="/" className="btn-primary">
            back to hotel
          </Link>
        </div>
      );
    }
    console.log()

    return (
      <>
        <StyledHero img={room.images[0].fields.file.url || this.state.defaultBcg}>
          <Banner title={room.name}>
            <Link to="/" className="btn-primary">
              {room.name}
            </Link>
          </Banner>
        </StyledHero>
        <div style={{marginTop:"35px"}}>
        <Title title="Thông tin phòng" />
        </div>
       

          <div id="carouselExampleIndicators"  class="carousel slide" data-bs-ride="true">
          <div className="infoRoom">
          <p id="content">{`Loại phòng: ${room.name}`}</p>
            <p id="content">{`Giá phòng: ${room.price}`}</p>
            <p id="content">{`Số người tối đa: ${room.capacity} người lớn`}</p>
          </div>
            <div class="carousel-indicators">
              {room.images.map((item, index) => {
                if (item === room.images[0]) {
                  return <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={index} class="active" aria-current="true" aria-label="Slide 1"></button>
                }
                else {
                  return <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={index} ></button>
                }
              })}

            </div>
            <div class="carousel-inner">
              {room.images.map((item, index) => {
                if (item === room.images[0]) {
                  return <div class="carousel-item active">
                    <img src={item.fields.file.url}  width="100%" height="600" style={{potition:"relative", objectFit: "cover", minWidth:"200px", marginLeft: "auto", marginRight: "auto", display: "block" }} alt="..." />
                  </div>
                }
                else {
                  return <div class="carousel-item">
                    <img src={item.fields.file.url} width="100%" height="600" style={{potition:"relative", objectFit: "cover", minWidth:"200px", marginLeft: "auto", marginRight: "auto", display: "block" }} alt={index} />
                  </div>;
                }
              })}
            </div>
            <button class="carousel-control-prev"  type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
      </>
    );
  }
}
