import React, { Component } from "react";

// import data
import roomsData from "../Data/rooms"

// react context-api
const RoomContext = React.createContext();

class RoomProvider extends Component {
  state = {
    rooms: [],
  };

  // getDate
  componentDidMount() {
    // this.getData
    let rooms = this.formatDate(roomsData);
    let featuredRooms = rooms.filter((room) => room.featured === true);

    let maxPrice = Math.max(...rooms.map((item) => item.price));
    let maxSize = Math.max(...rooms.map((item) => item.size));

    this.setState({
      rooms,
      featuredRooms,
      sortedRooms: rooms,
      loading: false,
      price: maxPrice,
      maxPrice,
      maxSize,
    });
  }

  formatDate(items) {
    let tempItems = items.map((item) => {
      let id = item.sys.id;
      let hotel = item.sys.hotel;
      let hotelMain = item.sys.hotel;
      let images = item.fields.map((e)=>e.image);
      let room = { ...item.fields, hotel, images, id, hotelMain };
      return room;
    });
    return tempItems;
  }

  getRoom = (slug , _roomslug) => {
    let tempRooms = [...this.state.rooms];
    const rooms = tempRooms.find((room) => room.hotel === slug);
    var singleRoom
    _roomslug && (rooms && (singleRoom = Object.values(rooms).find((res)=>
        res.slug === _roomslug
    )))
    if (_roomslug) {
      return singleRoom
    }
    return rooms;
  };

  handleChange = (event) => {
    /* const type = event.target.type;
    const name = event.target.name;
    const value = event.target.value; */
    /* console.log(
      `this is type: ${type}, this is name: ${name}, this is value: ${value}`
    ); */

    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;

    this.setState(
      {
        [name]: value,
      },
      this.filterRooms
    );
  };

  filterRooms = () => {
    let {
      rooms,
      type,
      capacity,
      price,
      minSize,
      maxSize,
      breakfast,
      pets,
    } = this.state;

    // all the room
    let tempRooms = [...rooms];

    // transform value
    capacity = parseInt(capacity);
    price = parseInt(price);

    // filter by type
    if (type !== "all") {
      tempRooms = tempRooms.filter((room) => room.type === type);
    }

    // filter by capacity
    if (capacity !== 1) {
      tempRooms = tempRooms.filter((room) => room.capacity >= capacity);
    }

    // filter by price
    tempRooms = tempRooms.filter((room) => room.price <= price);

    // filter bt size
    tempRooms = tempRooms.filter(
      (room) => room.size >= minSize && room.size <= maxSize
    );

    // filter by breakfast
    if (breakfast) {
      tempRooms = tempRooms.filter((room) => room.breakfast === true);
    }

    // filter by pets
    if (pets) {
      tempRooms = tempRooms.filter((room) => room.pets === true);
    }

    // change state
    this.setState({
      sortedRooms: tempRooms,
    });
  };

  render() {
    return (
      <RoomContext.Provider
        value={{
          ...this.state,
          getRoom: this.getRoom,
        }}
      >
        {this.props.children}
      </RoomContext.Provider>
    );
  }
}

const RoomConsumer = RoomContext.Consumer;

export function withRoomConsumer(Component) {
  return function ConsumerWrapper(props) {
    return (
      <RoomConsumer>
        {(value) => <Component {...props} context={value} />}
      </RoomConsumer>
    );
  };
}

export { RoomProvider, RoomConsumer, RoomContext };
