import React, { Component } from "react";

import { Link } from "react-router-dom";

// import assets
import defaultBcg from "../assets/img/defaul.jpg";
import Title from "../Components/Title/Title";

// import components
import { RoomContext } from "../Context/Context";
const ImageView = (item, state) => {
  return (
    <>
      <div className="img-container">
        <img src={item.image} height="350" style={{ potition: "relative", objectFit: "cover" }} alt="single room" />
        <Link to={`/hotels/${state}/${item.slug}`} className="btn-primary room-link">
          {item.name}
        </Link>
        <div className="layout-container">
        </div>
      </div>
    </>
  )
}
const ImageTitle = (item, state) => {
  return (
    <div className="room-info-list">
      <h3 className="room-info-title"> {item.name}</h3>
      <button className="button-seemore">
        <Link to={`/hotels/${state}/${item.slug}`}>
          <a style={{ color: "black", fontFamily: "'Calibri', sans-serif" }}>XEM THÊM</a>
        </Link>
      </button>
    </div>
  )
}
export default class SingleHotel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: this.props.slug,
      defaultBcg,
      hideNav: 0,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    console.log("aaaaa", this.state.hideNav)
    this.setState({ hideNav: window.innerWidth });
  }
  static contextType = RoomContext;

  render() {
    const { getRoom } = this.context;
    const room = getRoom(this.state.slug);
    if (!room) {
      return (
        <div className="error">
          <h3>no such room could be found!</h3>
          <Link to="/" className="btn-primary">
            back to hotel
          </Link>
        </div>
      );
    }
    return (
      <section className="roomslist-style">
        <Title title="Hệ thống phòng" />

        <div className="roomslist-row">
          {Object.values(room).map((item, index) => {
            return <>
              {item.image &&
                <>
                  {index % 2 === 0 ?
                    <div className="singleroom-list">
                      <div className="row">
                        <div className="col-md-8">
                          {ImageView(item, this.state.slug)}
                        </div>
                        <div className="col-md-4">
                          {ImageTitle(item, this.state.slug)}
                        </div>
                      </div>
                    </div>
                    :
                    <div className="singleroom-list">
                      <div class="row">
                        <div class="col-md-4">
                          {this.state.hideNav > 768 ? ImageTitle(item, this.state.slug) : ImageView(item, this.state.slug)}
                        </div>
                        <div class="col-md-8">
                          {this.state.hideNav <= 768 ? ImageTitle(item, this.state.slug) : ImageView(item, this.state.slug)}
                        </div>
                      </div>
                    </div>
                  }
                </>
              }
            </>
          }
          )}
        </div>
      </section>
    );
  }
}
