import React from "react";
// import react-router-dom
import { Link } from "react-router-dom";
import Banner from "../Components/Banner/Banner";
import FeaturedRooms from "../Components/FeaturedRooms/FeaturedRooms";
import Hotels from "../Components/Hotels/Hotels";

// imports components
import Hero from "../Components/Hero/Hero";
import Services from "../Components/Services/Services";
import Navbar from "../Components/Navbar/Navbar";

function Home() {
  // const { name, slug, images, price } = hotel;

  return (
    <>
      <Hero>
        <Banner
          title="Dương Châu Hotel Group"
        >
        </Banner>
      </Hero>
      <Hotels />
    </>
  );
}

export default Home;
