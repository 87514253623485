import { screen } from "@testing-library/react";
import styled from "styled-components";
// import assets
import defaultImg from "./../../assets/img/defaul.jpg";
const StyledHero = styled.header`
  min-height:calc(100vh - 50px);
  background: url(${(props) => (props.img ? props.img : defaultImg)});
  background-size: 100% 120%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  @media (max-width: 1000px) {
    min-height:50vh;
    background: url(${(props) => (props.img ? props.img : defaultImg)});
    background-size: 100% 120%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
  }
  @media (max-width: 500px) {
    min-height:35vh;
    background: url(${(props) => (props.img ? props.img : defaultImg)});
    background-size: 100% 120%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
  }
`;
StyledHero.defaultProps = {
};
export default StyledHero;
