import DCboutique from "../assets/img/hotels/DCboutique/DCboutique.jpg";
import DC from "../assets/img/hotels/DuongChau/DuongChau.jpg";
import DCimg2 from "../assets/img/hotels/DuongChau/IMG_0655 copy.jpg";
import DCimg3 from "../assets/img/hotels/DuongChau/IMG_0662.jpg"
import DCimg4 from "../assets/img/hotels/DuongChau/IMG_0672.jpg"
import DCimg5 from "../assets/img/hotels/DuongChau/z2597053212186_a9de7b27b01865b25971e5d729dbffed.jpg"


import img2 from "../assets/img/hotels/DCboutique/IMG_3094.jpg";
import img3 from "../assets/img/hotels/DCboutique/IMG_3104.jpg";
import img4 from "../assets/img/hotels/DCboutique/IMG_3108.jpg";
import img5 from "../assets/img/hotels/DCboutique/IMG_3110.jpg";
import img6 from "../assets/img/hotels/DCboutique/IMG_3111.jpg";

import NgocHa_img1 from "../assets/img/hotels/NgocHa/7.jpg"
import NgocHa_img2 from "../assets/img/hotels/NgocHa/2.jpg"
import NgocHa_img3 from "../assets/img/hotels/NgocHa/3.jpg"
import NgocHa_img4 from "../assets/img/hotels/NgocHa/4.jpg"
import NgocHa_img5 from "../assets/img/hotels/NgocHa/5.jpg"
import NgocHa_img6 from "../assets/img/hotels/NgocHa/6.jpg"
import NgocHa_img7 from "../assets/img/hotels/NgocHa/1.jpg"
import logo1 from "../assets/logo/logoDCboutique.jpg"
import logo2 from "../assets/logo/newlogo.png"
import logo3 from "../assets/logo/Ngoc Ha Hotel logo2.png"
import mainImgDCB from "../assets/img/hotels/DCboutique/IMG_3108.jpg"
import mainImgDC from "../assets/img/hotels/DCboutique/DC.jpg"
import mainImgNH from  "../assets/img/hotels/NgocHa/7.jpg"

const hotels = [
  {
    sys: {
      id: "1",
    },
    fields: {
      name: "Duong Chau Boutique",
      slug: "dc-boutique",
      type: "single",
      address: "P27-28 Đường N10, Phường Thống Nhất, Thành Phố Biên Hoà, Đồng Nai",
      phone:"0251 8820 899",
      capacity: 1,
      logo: logo1,
      mainImg: mainImgDCB,
      facebook: "https://www.facebook.com/Duongchauboutiquehotel",
      images: [
        {
          fields: {
            file: {
              url: DCboutique,
            },
          },
        },
        {
          fields: {
            file: {
              url: img2,
            },
          },
        },
        {
          fields: {
            file: {
              url: img3,
            },
          },
        },
        {
          fields: {
            file: {
              url: img4,
            },
          },
        },
        {
          fields: {
            file: {
              url: img5,
            },
          },
        },
        {
          fields: {
            file: {
              url: img6,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "2",
    },
    fields: {
      name: "Dương Châu Hotel",
      slug: "duongchau",
      address: "10/36 Lê Quý Đôn, Phường Tân Hiệp, Thành Phố Biên Hoà, Đồng Nai",
      phone:"0251 3895689",
      type: "single",
      capacity: 1,
      logo: logo2,
      mainImg: mainImgDC,
      facebook: "https://www.facebook.com/Duongchauhotel",  
      images: [
        {
          fields: {
            file: {
              url: DC,
            },
          },
        },
        {
          fields: {
            file: {
              url: DCimg2,
            },
          },
        },
        {
          fields: {
            file: {
              url: DCimg3,
            },
          },
        },
        {
          fields: {
            file: {
              url: DCimg4,
            },
          },
        },
        {
          fields: {
            file: {
              url: DCimg5,
            },
          },
        }
      ],
    },
  },
  {
    sys: {
      id: "3",
    },
    fields: {
      name: "Ngọc Hà Hotel",
      slug: "ngoc-ha",
      type: "single",
      address: "Số 32 đường N3, KDC Phú Gia 1, P. Trảng Dài, Biên Hoà, Đồng Nai",
      phone:"0251 221 2009",
      capacity: 1,
      facebook: "https://www.facebook.com/ngochahotelbienhoa/",  
      logo:logo3,
      mainImg:mainImgNH,
      images: [
        {
          fields: {
            file: {
              url: NgocHa_img1,
            },
          },
        },
        {
          fields: {
            file: {
              url: NgocHa_img2,
            },
          },
        },
        {
          fields: {
            file: {
              url: NgocHa_img3,
            },
          },
        },
        {
          fields: {
            file: {
              url: NgocHa_img4,
            },
          },
        },
        {
          fields: {
            file: {
              url: NgocHa_img5,
            },
          },
        },
        {
          fields: {
            file: {
              url: NgocHa_img6,
            },
          },
        },
        {
          fields: {
            file: {
              url: NgocHa_img7,
            },
          },
        },
      ],
    },
  }
]

export default hotels;
