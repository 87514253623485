import React from "react";

// import react-router-dom
import { Link } from "react-router-dom";

// import assets
import defaultImg from "../../assets/img/defaul.jpg";

// import prop-types
import PropTypes from "prop-types";

export default function Room({ room }) {
  const { name, slug, images } = room;

  return (
    <article className="room">
      <div className="img-container">
        <img src={images[0] || defaultImg} style={{height:"390px"}} alt="single room" />
        <Link to={`/hotels/${slug}`} className="btn-primary room-link">
          {name}
        </Link>
      </div>
      <p className="room-info">{name}</p>
    </article>
  );
}

Room.prototype = {
  room: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    price: PropTypes.number.isRequired,
  }),
};
