import React, { Component } from "react";

// import context
import { HotelContext } from "../../Context/HotelContext";

// import components
import HotelDetail from "../HotelDetail/HotelDetail";

import Title from "../Title/Title";

export default class Hotels extends Component {
  static contextType = HotelContext;

  render() {
    let { hotels } = this.context;
    hotels = hotels.map((hotel) => {
      return <HotelDetail key={hotel.id} hotel={hotel} />;
    });

    return (
      <section className="featured-rooms">
        <Title title="Hệ thống khách sạn tại thành phố Biên Hòa - Đồng Nai" />
        <div className="featured-rooms-center">
          { hotels}
        </div>
      </section>
    );
  }
}
